body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {margin-top:70px}

.card{
  margin-top:10px;
  /* height:95%; */
}

.card-img-top
{
  padding-bottom: 40px;
}

.menu-thumbnail-body
{
  text-align: center;
  position: absolute;
  bottom: 0;
  width:100%;
  padding:10px;
  background-color: rgba(239,239,239,.77)!important;
}
.menu-thumbnail-title
{
  
}

.section-title
{
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.carousel-item{
  height: 32rem;
}

.page-footer{
  text-align: center;
  background: rgb(250, 251, 252);
  /* border-top:1px solid #dee2e6!important; */
}

.product-thumbnail-title{
  width: 100%;
  text-align: center;
}

.product-thumbnail-price{
  width: 100%;
  text-align: center;
}

.product-thumbnail-body
{
}

.product-thumbnail-addtocart
{
  width: 100%;
  padding:10px;
}

.product-thumbnail-addtocart a
{
  background-color: #1b1a20;
    color: #fff;
    padding: 15px 0;
    margin-top: 10px;
    cursor: pointer;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    width:100%;
}

.product-thumbnail-addtocart a:hover{
  color: #fff;
  background-color: #eabf00;
}

.error{
  position: fixed;
  text-align: center;
  top: 20%;
  width: 100%;
  height: 100%;
  }